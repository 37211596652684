"use client";
import { useEffect, useState } from "react";
// import AgileAppLogo from "../assets/svg/agileAp-Logo-Standard1.svg";
import { AgileAppLogo } from "../assets/svg";
import { useLocation, useParams } from "react-router-dom";
import AgileAPLogo from "../assets/AgileAp-Logo-White.png";
import { Helper } from "../utils/helpers";
import ReactDOM from "react-dom";
interface MenuProps {
  modal: any;
}

const MenuView: React.FC<MenuProps> = ({ modal }) => {
  return ReactDOM.createPortal(<>{modal}</>, document.body);
};
interface NavbarProps {
  currentPage?: string;
}
const Header: React.FC<NavbarProps> = ({ currentPage = "Home" }) => {
  const location = useLocation();
  const { country } = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const countryCode =
    country && country != "undefined"
      ? country
      : localStorage.getItem("country")
      ? localStorage.getItem("country")
      : "sg";
  const [currentSection, setCurrentSection] = useState("");
  let { pathname } = useLocation();
  console.log(country, localStorage.getItem("country"), countryCode, "countryCode");

  const handleOpenSideBar = () => {
    // const menu: any = document.querySelector("#navbar-default");
    // menu.classList.toggle("invisible");
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickRequestDemo = () => {
    const emailAddress = "sales@agileap.com";
    const subject = "Inquiry About AgileAP";
    const body =
      "Hello,\n\nI am interested in the AgileAP. I would like to request for a demo. \nInfomation: \nTitle: \nFull Name: \nCompany Name: \nEmail: ";

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };
  const handleChangeSection = (section: string) => {
    Helper.CallAnalyticEvent(window.location.pathname, "Click Logo");
    console.log("location", location);
    if (baseName != "/") {
      window.location.href = "https://agileap.com";
    } else if (location.pathname != "/") {
      window.location.href = baseName;
    }
    setCurrentSection(section);
    scrollToSection(section);
    // const menu: any = document.querySelector("#navbar-default");
    // menu.classList.toggle("invisible");
    setIsMenuOpen(false);
  };

  const scrollToSection = (id: any) => {
    const section = document.getElementById(id.replace("#", ""));

    console.log(section, id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  };

  const [baseName, setBaseName] = useState(process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : "");
  const [appUrl, setAppUrl] = useState(process.env.REACT_APP_URL ? process.env.REACT_APP_URL : "");
  const mainPage = process.env.REACT_APP_MAIN_PAGE ? process.env.REACT_APP_MAIN_PAGE : "";

  const handleClick = () => {
    window.location.href = appUrl;
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 770) setIsMenuOpen(false);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log(currentPage,"currentPage")

  return (
    <nav className="bg-white flex sticky w-full z-20 top-0 left-0 justify-center ">
      <div className=" flex flex-wrap items-center justify-between py-4 w-lgMain">
        <a onClick={() => handleChangeSection("#home")} className="flex items-center cursor-pointer -ml-2.5 sm:ml-0">
          {/* <AgileAppLogo width={147} /> */}
          <img src={AgileAPLogo} width={155} />
        </a>
        <div className="flex lg:gap-6 gap-1">
          <button
            type="button"
            onClick={handleOpenSideBar}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          {isMenuOpen && (
            <MenuView
              modal={
                <div
                  className="md:relative fixed top-14 lg:top-auto md:top-auto right-0 z-40 ml-1 w-full md:block md:w-auto"
                  id="navbar-default"
                >
                  <ul className="font-medium text-secondary text-sm flex flex-col p-4 gap-1 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row  md:mt-0 md:border-0 md:bg-white lg:gap-10">
                    <>
                      {currentPage === "Timing" ? (
                        <></>
                      ) : (
                        <>
                          <a
                            href={`/${countryCode}/pricing`}
                            className={`${
                              currentPage === "Pricing"
                                ? "bg-primary text-white md:border-b-4 md:border-primary md:bg-white md:text-secondary md:rounded-none"
                                : "hover:bg-gray-100"
                            } px-2 cursor-pointer rounded`}
                            onClick={() => {
                              Helper.CallAnalyticEvent(window.location.pathname, "Click Pricing Tab");
                            }}
                          >
                            <span className="block py-2 rounded">Pricing</span>
                          </a>
                          {countryCode == "sg" && (
                            <a
                              href={`/${countryCode}/invoicenow`}
                              className={`${
                                currentPage === "InvoiceNow"
                                  ? "bg-primary text-white md:border-b-4 md:border-primary md:bg-white md:text-secondary md:rounded-none"
                                  : "hover:bg-gray-100"
                              } px-2 cursor-pointer rounded`}
                              onClick={() => {
                                Helper.CallAnalyticEvent(window.location.pathname, "Click InvoiceNow Tab");
                              }}
                            >
                              <span className="block py-2 rounded">InvoiceNow</span>
                            </a>
                          )}
                          <a
                            href={`/${countryCode}/news`}
                            className={`${
                              currentPage === "News"
                                ? "bg-primary text-white md:border-b-4 md:border-primary md:bg-white md:text-secondary md:rounded-none"
                                : "hover:bg-gray-100"
                            } px-2 cursor-pointer rounded`}
                            onClick={() => {
                              Helper.CallAnalyticEvent(window.location.pathname, "Click News Tab");
                            }}
                          >
                            <span className="block py-2 rounded">News</span>
                          </a>
                          {countryCode == "sg" && (
                            <a
                              href={`/${countryCode}/freemium`}
                              className={`${
                                currentPage === "Freemium"
                                  ? "bg-primary text-white md:border-b-4 md:border-primary md:bg-white md:text-secondary md:rounded-none"
                                  : "hover:bg-gray-100"
                              } px-2 cursor-pointer rounded`}
                              onClick={() => {
                                Helper.CallAnalyticEvent(window.location.pathname, "Click FOC Tab");
                              }}
                            >
                              <span className="block py-2 rounded">C5 FOC Package</span>
                            </a>
                          )}
                          <a
                            href={`/${countryCode}/pricing/free`}
                            className={`bg-white rounded-xl text-primary mt-2 border-2 w-fit border-primary px-2.5 py-[6px] mb-[5px] cursor-pointer rounded`}
                            onClick={() => {
                              Helper.CallAnalyticEvent(window.location.pathname, "Click Try For Free Button");
                            }}
                          >
                            Try For Free
                          </a>
                        </>
                      )}
                    </>
                    {currentPage === "Timing" ? (
                      <li className="lg:hidden bg-primary w-fit rounded-xl text-white md:hidden px-4 lg:px-0 md:px-0 md:flex"></li>
                    ) : (
                      <li
                        className="lg:hidden bg-primary w-fit rounded-xl text-white md:hidden px-4 lg:px-0 md:px-0 md:flex"
                        onClick={() => {
                          Helper.CallAnalyticEvent(window.location.pathname, "Click Login Now Button");
                          handleClick();
                        }}
                      >
                        <a className="block py-2 rounded cursor-pointer">Login Now</a>
                      </li>
                    )}
                  </ul>
                </div>
              }
            />
          )}
          <div
            className="hidden md:relative absolute top-14 lg:top-auto md:top-auto right-0 z-40 ml-1 w-full md:block md:w-auto"
            id="navbar-default-desktop"
          >
            <ul className="font-medium text-secondary text-sm flex flex-col p-4 gap-1 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row  md:mt-0 md:border-0 md:bg-white lg:gap-10">
              {/* {(((location.pathname == `/${countryCode}` || location.pathname == `/${countryCode}/`) &&
                mainPage == "") ||
                currentPage === "Timing" ||
                currentPage === "InvoiceNow" ||
                currentPage === "e-invoicing" ||
                currentPage === "Pricing" ||
                currentPage === "Terms and conditions" ||
                currentPage === "Contact Us" ||
                currentPage === "Press Release") && ( */}
              <>
                {/* <li
                                    className={`${currentSection === "#product" ? "bg-primary text-white" : "hover:bg-gray-10"
                                        } rounded px-2 md:border-0 border-b border-gray-200 cursor-pointer`}
                                >
                                    <a onClick={() => handleChangeSection("#product")} className="block py-2" aria-current="page">
                                        Product
                                    </a>
                                </li>
                                <li
                                    className={`${currentSection === "#solution" ? "bg-primary text-white" : "hover:bg-gray-100"
                                        }  rounded px-2 md:border-0 border-b border-gray-200 cursor-pointer`}
                                >
                                    <a onClick={() => handleChangeSection("#solution")} className="block py-2 rounded ">
                                        Solution
                                    </a>
                                </li>*/}
                {/* <li onClick={() => handleChangeSection("#services")} className={`${currentSection === '#services' ? 'bg-primary text-white' : 'hover:bg-gray-100'} rounded px-2 md:border-0 border-b border-gray-200`}>
                                <a href="#services" className="block py-2 rounded">Services</a>
                            </li> */}
                {currentPage === "Timing" ? (
                  <></>
                ) : (
                  <>
                    <a
                      href={`/${countryCode}/pricing`}
                      className={`${
                        currentPage === "Pricing"
                          ? "bg-primary text-white md:border-b-4 md:border-primary md:bg-white md:text-secondary md:rounded-none"
                          : "hover:bg-gray-100"
                      } px-2 cursor-pointer rounded`}
                      onClick={() => {
                        Helper.CallAnalyticEvent(window.location.pathname, "Click Pricing Tab");
                      }}
                    >
                      <span className="block py-2 rounded">Pricing</span>
                    </a>
                    {countryCode == "sg" && (
                      <a
                        href={`/${countryCode}/invoicenow`}
                        className={`${
                          currentPage === "InvoiceNow"
                            ? "bg-primary text-white md:border-b-4 md:border-primary md:bg-white md:text-secondary md:rounded-none"
                            : "hover:bg-gray-100"
                        } px-2 cursor-pointer rounded`}
                        onClick={() => {
                          Helper.CallAnalyticEvent(window.location.pathname, "Click InvoiceNow Tab");
                        }}
                      >
                        <span className="block py-2 rounded">InvoiceNow</span>
                      </a>
                    )}
                    {/* {countryCode == "my" && (
                                        <a
                                            href={`/${countryCode}/e-invoicing`}
                                            className={`${currentPage === "e-invoicing" ? "bg-primary text-white md:border-b-4 md:border-primary md:bg-white md:text-secondary md:rounded-none" : "hover:bg-gray-100"
                                            } px-2 cursor-pointer rounded`}
                                        >
                                            <span className="block py-2 rounded">
                                                e-Invoicing
                                            </span>
                                        </a>
                                    )} */}
                    <a
                      href={`/${countryCode}/news`}
                      className={`${
                        currentPage === "News"
                          ? "bg-primary text-white md:border-b-4 md:border-primary md:bg-white md:text-secondary md:rounded-none"
                          : "hover:bg-gray-100"
                      } px-2 cursor-pointer rounded`}
                      onClick={() => {
                        Helper.CallAnalyticEvent(window.location.pathname, "Click News Tab");
                      }}
                    >
                      <span className="block py-2 rounded">News</span>
                    </a>
                    {countryCode == "sg" && (
                      <a
                        href={`/${countryCode}/freemium`}
                        className={`${
                          currentPage === "Freemium"
                            ? "bg-primary text-white md:border-b-4 md:border-primary md:bg-white md:text-secondary md:rounded-none"
                            : "hover:bg-gray-100"
                        } px-2 cursor-pointer rounded`}
                        onClick={() => {
                          Helper.CallAnalyticEvent(window.location.pathname, "Click FOC Tab");
                        }}
                      >
                        <span className="block py-2 rounded">C5 FOC Package</span>
                      </a>
                    )}
                    <a
                      href={`/${countryCode}/pricing/free`}
                      className={`bg-white rounded-xl text-primary border-2 w-fit border-primary px-2.5 py-[6px] mb-[5px] cursor-pointer rounded`}
                      onClick={() => {
                        Helper.CallAnalyticEvent(window.location.pathname, "Click Try For Free Button");
                      }}
                    >
                      Try For Free
                    </a>
                  </>
                )}
                {/* <li
                                    className={`${currentPage === "Home" || currentSection === "#aboutus" ? "bg-primary text-white bg-primary md:border-b-4 md:border-primary md:bg-white md:text-secondary md:rounded-none" : "hover:bg-gray-100"
                                    } px-2 cursor-pointer rounded`}
                                >
                                    <a onClick={() => handleChangeSection("#aboutus")} className="block py-2 rounded ">
                                        About Us
                                    </a>
                                </li> */}
              </>
              {/* )} */}
              {currentPage === "Timing" ? (
                <li className="lg:hidden bg-primary w-fit rounded-xl text-white md:hidden px-4 lg:px-0 md:px-0 md:flex"></li>
              ) : (
                <li
                  className="lg:hidden bg-primary w-fit rounded-xl text-white md:hidden px-4 lg:px-0 md:px-0 md:flex"
                  onClick={() => {
                    Helper.CallAnalyticEvent(window.location.pathname, "Click Login Now Button");
                    handleClick();
                  }}
                >
                  <a className="block py-2 rounded cursor-pointer">Login Now</a>
                </li>
              )}
            </ul>
          </div>
          <div className="hidden md:block text-xs order-2">
            {currentPage === "Timing" ? (
              <></>
            ) : (
              <button
                type="button"
                onClick={() => {
                  Helper.CallAnalyticEvent(window.location.pathname, "Click Login Now Button");
                  handleClick();
                }}
                className="font-medium text-white bg-primary rounded-lg hover:ring-0 hover:outline-none hover:bg-opacity-70 active:bg-opacity-50 font-medium rounded-xl text-sm px-4 py-2 text-center mr-3 md:mr-0 "
              >
                Login Now
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Header;
