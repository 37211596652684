import React, {useCallback, useEffect, useState} from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface ReCaptchaComponentProps {
    onVerify: (token: string | null) => void;
}
const ReCaptchaFormComponent= ({onVerify}:ReCaptchaComponentProps) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const token = await executeRecaptcha('submit');
        onVerify(token);
    }, [executeRecaptcha]);

    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return(
        <>
            {/*<button className={`bg-theme_color text-white rounded-md p-2`} onClick={handleReCaptchaVerify}>Verify recaptcha</button>*/}
        </>
    )
};

export default ReCaptchaFormComponent;
