import { HomeLayout } from "../layouts/HomeLayout";
import * as React from "react";
import { useEffect, useState } from "react";
import { apiLogin } from "../services";
import { Loader } from "../components/Loader";
import { colors } from "../constants/colourConfigurations";
import { Icon, IconType } from "../components/Icon";
import { LoaderV2 } from "../components/Loader/LoaderV2";
import { CookieService } from "../utils/cookieservice";
import { Crypto } from "../utils/encryption";
import { isMobile, isIPad13, isTablet } from "react-device-detect";
import MetatagHelmet from "../components/MetatagHelmet";
import { useParams } from "react-router-dom";
import { Helper } from "../utils/helpers";
import InvoiceNowLGLogo from "../assets/image/InvoiceNow_Logo.png";

const baseName = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : "";
export const Freemium = () => {
  const { country, mode } = useParams();
  const countryCode = localStorage.getItem("country");
  const emailAddress = "sales@agileap.com";
  const subject = "Inquiry About AgileAP";
  const body =
    "Hello,\n\nI am interested in the AgileAP. I would like to request for a demo. \nInfomation: \nTitle: \nFull Name: \nCompany Name: \nEmail: ";
  const handleGoHome = () => {
    window.history.pushState({}, "Home", baseName);
    window.history.go();
  };
  const tempMenuLists = [
    {
      label: "Send and Receive Documents via InvoiceNow (include both B2B, B2C InvoiceNow transactions)",
      description: "",
      unit: "",
      menuName: "peppolTransactionNo",
      subMenuName: "",
      showOutbound: false,
    },
    {
      label: "Send Documents (Invoice, CN) via Email",
      description: "",
      unit: "",
      menuName: "emailTransactionNo",
      subMenuName: "",
      showOutbound: false,
    },
    {
      label: "C5 Submission (Invoice, CN)",
      description: "",
      unit: "",
      menuName: "c5TransactionNo",
      subMenuName: "",
      showOutbound: false,
    },
    {
      label: "Invoice",
      description: "",
      unit: "Invoices",
      menuName: "Invoice Received",
      subMenuName: "Invoice",
      showOutbound: false,
    },
    {
      label: "Credit Note",
      description: "",
      unit: "CNs",
      menuName: "Credit Note",
      subMenuName: "Credit Note",
      showOutbound: false,
    },
    {
      label: "Vendor / Customer Management",
      description: "",
      unit: "",
      menuName: "Vendor / Customer",
      subMenuName: "",
    },
    {
      label: "System Administration",
      description: "",
      unit: "",
      menuName: "",
      subMenuName: "",
    },
    {
      label: "Dashboard and Analytics",
      description: "",
      unit: "",
      menuName: "",
      subMenuName: "",
    },
    // {
    //   label: "Budget Management",
    //   description: "",
    //   unit: "Budgets",
    //   menuName: "Budget",
    //   subMenuName: "",
    // },
    // {
    //   label: "Contract Management",
    //   description: "",
    //   unit: "Contracts",
    //   menuName: "Contract",
    //   subMenuName: "",
    // },
    // {
    //   label: "Timesheet",
    //   description: "",
    //   unit: "Timesheet",
    //   menuName: "Timesheet",
    //   subMenuName: "",
    // },
    {
      label: "Products and Services",
      description: "",
      unit: "",
      menuName: "Products and Services",
      subMenuName: "",
    },
    {
      label: "Claims / Petty Cash Purchase",
      description: "",
      unit: "Claims",
      menuName: "Claims",
      subMenuName: "",
    },
    {
      label: "Quotation",
      description: "",
      unit: "Quotes",
      menuName: "Quotation",
      subMenuName: "",
      useMenuUnit: false,
    },
    {
      label: "Purchase Order",
      description: "",
      unit: "POs",
      menuName: "Purchase Order",
      subMenuName: "",
      useMenuUnit: false,
      showOutbound: false,
    },
    {
      label: "Purchase Requisition",
      description: "",
      unit: "PR",
      menuName: "Purchase Requisition",
      subMenuName: "",
      useMenuUnit: false,
    },
    {
      label: "Delivery & Goods Receipt",
      description: "",
      unit: "DO",
      subUnit: "GR",
      menuName: "Delivery Order",
      subMenuName: "",
      useMenuUnit: false,
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [menuList, setMenuList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const handleChoosePlan = (encryptData: string) => {
    Helper.CallAnalyticEvent(window.location.pathname, "Click Choose This Plan Button");
    let token = CookieService.get("x-token");
    let currentDate = new Date();
    let expiredate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes() + 1,
      currentDate.getSeconds()
    );

    let key = process.env.REACT_APP_PLAN_KEY ? process.env.REACT_APP_PLAN_KEY : "sub-plan";
    CookieService.set(key, encryptData, new Object(), expiredate);
    console.log(selectBilling, "selectBilling");
    CookieService.set("selected-billing", Crypto.encrypt(selectBilling), new Object(), expiredate);

    let baseName = process.env.REACT_APP_UI_LINK;

    // if (token) {
    //   newTab.location.assign(baseName + "subscription/free");
    // } else {
    setTimeout(() => {
      let newTab = window.open(baseName, "_blank");
      if (newTab) {
        var currentCountry = countryCode != "" ? countryCode : "sg";
        newTab.location.assign(baseName + `companyregister/${currentCountry}/0${mode && mode == "free" ? "/free" : ""}`);
      }
    }, 500);
    // }
  };

  useState(() => {
    if (isLoading) {
      var currentCountry = "sg";
      if (countryCode && countryCode != "") currentCountry = countryCode;
      if (country && currentCountry != "" && currentCountry != country) {
        currentCountry = country;
        localStorage.setItem("country", country);
      }
      apiLogin("admin/get-public-package-list")
        .post({ id: null, authenticated: false, body: { encryptedId: "", countryCode: currentCountry } })
        .then((resp) => {
          console.log("response package data : ", resp);
          if (resp && resp.data != null && resp.data.response) {
            // let planNames = ["Starter", "Basic", "Standard", "Enterprise"];
            let result = resp.data.response;

            // let packageList: any = [];
            // planNames.map((name) => {
            //   let pkg = result.packageList.find((e: any) => e.planName == name);
            //   packageList.push(pkg);
            // });
            const sortedArray = result.packageList.sort((a: any, b: any) => {
              if (a.planName === "Enterprise" && b.planName !== "Enterprise") {
                return -1; // a comes first
              }
              if (b.planName === "Enterprise" && a.planName !== "Enterprise") {
                return 1; // b comes first
              }
              return 0; // keep the order for other items
            });

            setMenuList(result.menuList);
            setPackageList(sortedArray);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  });

  function isMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  const handleClick = () => {
    Helper.CallAnalyticEvent(window.location.pathname, "Click Contact Us Button");
    window.open(`https://wa.me/6580880170/?text=${encodeURIComponent(body)}`, "", "noreferrer");
    // if (isMobile) {
    //   const phoneNumber = "+65 6684 7044";
    //   window.location.href = `tel:${phoneNumber}`;
    // } else {
    //   const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    //     body
    //   )}`;
    //   window.location.href = mailtoLink;
    // }
  };
  const platform = window.navigator.platform;
  const isIOS = !!platform.match(/iPhone|iPod|iPad/);
  const [selectBilling, setSelectedBilling] = useState("Monthly");
  const mainContentDesktop = (
    <>
      <MetatagHelmet
        title="AgileAP - Subscription Plan | Singapore InvoiceNow Compliant"
        description="AgileAP offers a comprehensive suite of features to meet all your accounts payable and account receivable needs."
        url={window.location.href}
      />
      <div className={`w-lgMain py-10`}>
        <div className={`gap-4 font-bold text-4xl md:text-5xl text-left font-poppins relative`}>
          <div className={`mb-3`}>We've got a plan</div>
          <div className={`mb-10`}>that's perfect for you</div>
          <div className={`text-lg font-normal`}>
            AgileAP offers a robust suite of features designed to optimise accounts payable and receivable processes.
            <br />
            The C5 FOC Package, available exclusively for GST-registered businesses, is offered in alignment with IMDA’s
            initiative. Businesses that sign up for this package between April 1, 2025, and March 31, 2027, will receive
            a two-year free subscription.
          </div>
        </div>
        <div className={`my-10`}>
          {/* <div className={`flex `}>
                        <div onClick={() => setSelectedBilling("Monthly")}
                             className={`cursor-pointer rounded-l-lg py-3 px-7 ${selectBilling == "Monthly" ? 'bg-primary text-white' : 'bg-white text-black border-2 border-solid border-gray-300'}`}>Monthly
                            Billing
                        </div>
                        <div onClick={() => setSelectedBilling("Yearly")}
                             className={`cursor-pointer rounded-r-lg py-3 px-7 ${selectBilling == "Yearly" ? 'bg-primary text-white' : 'bg-white text-black border-2 border-solid border-gray-300'}`}>Annual
                            Billing
                        </div>
                    </div> */}
        </div>
        {!isLoading ? (
          <>
            {/* {isMobileDevice() == true || isIOS == true ? ( */}
            <div className="flex justify-center w-full block lg:hidden">
              <div className={`md:flex w-full`}>
                {packageList.length > 0 &&
                  packageList.map((pkg: any) => {
                    const planAmount = pkg.priceDetails?.find(
                      (e: any) => e.pricingTypeName === selectBilling.toLowerCase()
                    )?.planAmount;
                    return (
                      <div
                        className={`border p-7 text-center mb-4 w-full ${
                          pkg.planName == "Enterprise" && "border-primary border-2"
                        }`}
                      >
                        <div className="flex flex-col justify-between">
                          <div>
                            <div className={`text-base font-bold mb-4`}>
                              {pkg.planName == "Enterprise" ? "FOC Package" : pkg.planName}
                            </div>
                            {planAmount && planAmount > 0 ? (
                              <div className={`mb-6`}>
                                <div className="md:flex flex-wrap md:gap-1 items-center justify-center ">
                                  <span className={`font-bold text-base`}>
                                    {pkg.currencyTypeName ? pkg.currencyTypeName : "SGD"}{" "}
                                  </span>
                                  <span className={`font-bold text-2xl`}>{planAmount}</span>
                                  <span className={`text-gray-400 font-normal`}>
                                    {" "}
                                    / {selectBilling === "Monthly" ? "Month" : "Year"}
                                  </span>
                                </div>
                                <div className="text-xs">Billed Monthly</div>
                              </div>
                            ) : (
                              <div className={`mb-6`}>
                                <div className="md:flex truncate flex-wrap md:gap-1 items-center justify-center ">
                                  <span className={`font-bold text-lg`}>{"SGD"}</span>
                                  <span className={`font-bold text-2xl`}>{"0"}</span>
                                  <span className={`text-gray-400 font-normal`}> / Month</span>
                                </div>
                                <div className="text-xs italic">U.P. SGD 150.00</div>
                                <div className="text-xs">Billed Monthly</div>
                              </div>
                            )}
                          </div>
                          {planAmount && planAmount > 0 ? (
                            <>
                              <div
                                onClick={() => handleChoosePlan(pkg.encryptedId)}
                                className={`flex justify-center cursor-pointer hover:opacity-70 active:bg-gray-400 text-base py-3 px-5 bg-gray-300 text-gray-500 rounded-lg`}
                              >
                                {mode && mode == "free" ? "Start Your Free Trial" : "Sign Up and Choose This Plan"}
                              </div>
                              {mode && mode == "free" && (
                                <div className="text-xs pt-1.5 pb-0 mb-0 h-5">No Credit Card Required</div>
                              )}
                            </>
                          ) : (
                            <>
                              <div
                                onClick={() => handleChoosePlan("Freemium")}
                                className={`flex justify-center cursor-pointer hover:opacity-70 active:bg-gray-400 text-base py-3 px-5 bg-primary text-white rounded-lg`}
                              >
                                Sign Up and Choose This Plan
                              </div>
                              {mode && mode == "free" && <div className="text-xs pt-1.5 pb-0 mb-0 h-5"></div>}
                            </>
                          )}
                        </div>
                        <div className="mt-7">
                          <>
                            <div className={`w-full gap-2 items-start text-sm`}>
                              <div className={`w-full flex gap-2 mb-1 font-semibold items-start text-sm mb-1`}>
                                1 Entity
                              </div>
                              {pkg.details.length > 0 &&
                                pkg.details
                                  .filter((e: any) => e.menuName == "User Management")
                                  .map((menu: any) => {
                                    return (
                                      <div className={`w-full gap-2 items-start text-sm`}>
                                        <div className={`flex justify-start font-semibold mb-1`}>
                                          {menu.txUsage == 0
                                            ? `1 User`
                                            : // <div className={`w-full flex gap-2 items-start text-sm`}>
                                            //   <Icon type={IconType.TickIcon} className="w-4" />
                                            //   <div className={`flex break-words text-left`}>User Management</div>
                                            // </div>
                                            menu.txUsage > 1
                                            ? `${menu.txUsage} Users`
                                            : `1 User`}
                                        </div>
                                      </div>
                                    );
                                  })}
                              {/* <div className={`flex break-words`}>User Management</div> */}
                            </div>
                          </>
                          {tempMenuLists.length > 0 &&
                            tempMenuLists.map((menu: any) => {
                              if (menu.showOutbound == true) {
                                console.log(
                                  "pkg ==> ",
                                  menu.showOutbound,
                                  ", ",
                                  menu.menuName,
                                  ", ",
                                  (pkg.details?.find((e: any) => e.menuName == menu.menuName)?.txUsage
                                    ? pkg.details?.find((e: any) => e.menuName == menu.menuName)?.txUsage
                                    : 0) +
                                    (pkg.details?.find((e: any) => e.menuName == menu.subMenuName)?.txUsage
                                      ? pkg.details?.find((e: any) => e.menuName == menu.subMenuName)?.txUsage
                                      : 0)
                                );
                              }
                              return (
                                <div className={`w-full flex gap-2 items-start text-sm`}>
                                  <div className={`flex justify-start font-semibold text-left`}>
                                    {menu.menuName == "" ? (
                                      <Icon type={IconType.TickIcon} className="w-4" />
                                    ) : menu.menuName == "peppolTransactionNo" ? (
                                      <span className="flex items-center  mb-1">
                                        {pkg.peppolTransactionNo > 0 ? (
                                          pkg.peppolTransactionNo +
                                          " " +
                                          (country == "sg" ? "InvoiceNow" : "Peppol") +
                                          " Documents"
                                        ) : (
                                          <div className={`w-full flex gap-2 items-start text-sm`}>
                                            <Icon type={IconType.TickIcon} className="w-4" />
                                            <div className={`flex break-words text-left`}>
                                              Unlimited {country == "sg" ? "InvoiceNow" : "Peppol"} Document (include
                                              both B2B, B2C InvoiceNow transactions)
                                            </div>
                                          </div>
                                        )}
                                      </span>
                                    ) : menu.menuName == "emailTransactionNo" ? (
                                      <span className="flex items-center mb-1">
                                        {pkg.emailTransactionNo > 0 ? (
                                          pkg.emailTransactionNo + " Email Documents"
                                        ) : (
                                          <div className={`w-full flex gap-2 items-start text-sm`}>
                                            <Icon type={IconType.TickIcon} className="w-4" />
                                            <div className={`flex break-words text-left`}>Unlimited Email Document</div>
                                          </div>
                                        )}
                                      </span>
                                    ) : menu.menuName == "c5TransactionNo" ? (
                                      <>
                                        {pkg.planName != "Enterprise" ? (
                                          <div className={`w-full flex gap-2 items-start text-sm`}>
                                            <Icon type={IconType.TickIcon} className="w-4" />
                                            <div className={`flex break-words text-left`}>C5 Submission</div>
                                          </div>
                                        ) : (
                                          <div className={`w-full flex gap-2 items-start text-sm`}>
                                            <Icon type={IconType.TickIcon} className="w-4" />
                                            <div className={`flex break-words text-left`}>Unlimited C5 Submission</div>
                                          </div>
                                        )}
                                      </>
                                    ) : pkg.details?.find((e: any) => e.menuName == menu.menuName) ? (
                                      menu.showOutbound == true ? (
                                        pkg.details?.find((e: any) => e.menuName == menu.menuName).txUsage > 0 ||
                                        pkg.details?.find((e: any) => e.menuName == menu.subMenuName).txUsage > 0 ? (
                                          <span className="flex mb-1">
                                            {(pkg.details?.find((e: any) => e.menuName == menu.menuName).txUsage
                                              ? pkg.details?.find((e: any) => e.menuName == menu.menuName).txUsage
                                              : 0) +
                                              (pkg.details?.find((e: any) => e.menuName == menu.subMenuName).txUsage
                                                ? pkg.details?.find((e: any) => e.menuName == menu.subMenuName).txUsage
                                                : 0)}{" "}
                                            {menu.useMenuUnit == true ? menu.menuName + "s" : menu.unit}
                                          </span>
                                        ) : (
                                          <Icon type={IconType.TickIcon} className="w-4" />
                                        )
                                      ) : pkg.details?.find((e: any) => e.menuName == menu.menuName).txUsage > 0 ? (
                                        <span className="flex mb-1">
                                          {pkg.details?.find((e: any) => e.menuName == menu.menuName).txUsage}{" "}
                                          {menu.useMenuUnit == true ? menu.menuName + "s" : menu.unit}
                                        </span>
                                      ) : (
                                        <>
                                          {pkg.planName == "Enterprise" &&
                                          (menu.menuName == "Quotation" ||
                                            menu.menuName == "Purchase Order" ||
                                            menu.menuName == "Purchase Requisition" ||
                                            menu.menuName == "Delivery Order") ? (
                                            <></>
                                          ) : (
                                            // <Icon type={IconType.TickIcon} className="w-4" />
                                            <Icon type={IconType.TickIcon} className="w-4" />
                                          )}
                                        </>
                                      )
                                    ) : menu.subMenuName != "" ? (
                                      pkg.details?.find((e: any) => e.menuName == menu.subMenuName) ? (
                                        pkg.details?.find((e: any) => e.menuName == menu.subMenuName).txUsage > 0 ? (
                                          <span className="flex mb-1">
                                            {pkg.details?.find((e: any) => e.menuName == menu.subMenuName).txUsage}{" "}
                                            {menu.subUnit}
                                          </span>
                                        ) : (
                                          <Icon type={IconType.TickIcon} className="w-4" />
                                        )
                                      ) : (
                                        <></>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  {menu.menuName == "" ? (
                                    <div className={`flex break-words text-left mb-1`}>
                                      {menu.label}{" "}
                                      {menu.description != "" ? (
                                        <span className="text-sm text-darkOrange">{menu.description}</span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : pkg.details?.find((e: any) => e.menuName == menu.menuName) ? (
                                    pkg.details?.find((e: any) => e.menuName == menu.menuName).txUsage > 0 ? (
                                      <></>
                                    ) : (
                                      <>
                                        {pkg.planName == "Enterprise" &&
                                        (menu.menuName == "Quotation" ||
                                          menu.menuName == "Purchase Order" ||
                                          menu.menuName == "Purchase Requisition" ||
                                          menu.menuName == "Delivery Order") ? (
                                          <></>
                                        ) : (
                                          <div className={`flex break-words text-left mb-1`}>
                                            {menu.label}{" "}
                                            {menu.description != "" ? (
                                              <span className="text-sm text-darkOrange">{menu.description}</span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )
                                  ) : menu.subMenuName != "" ? (
                                    pkg.details?.find((e: any) => e.menuName == menu.subMenuName) ? (
                                      pkg.details?.find((e: any) => e.menuName == menu.subMenuName).txUsage > 0 ? (
                                        <></>
                                      ) : (
                                        <div className={`flex break-words text-left mb-1`}>
                                          {menu.label}{" "}
                                          {menu.description != "" ? (
                                            <span className="text-sm text-darkOrange">{menu.description}</span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      )
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {/* ) : ( */}
            <div className={`hidden lg:block`}>
              <div className={`w-full grid grid-cols-1 md:grid-cols-5 sm:grid-cols-2 `}>
                <div className={`border border-pricingBorder flex flex-col justify-center p-7`}>
                  <div className={`font-bold text-xl mb-2`}>Choose Your Plan</div>
                  <div className={`text-sm text-gray-400`}>
                    Find the best plan for your organisation
                    {/* Choose your plan according to your organisational plan */}
                  </div>
                </div>
                {packageList.length > 0 &&
                  packageList.map((pkg: any) => {
                    const planAmount = pkg.priceDetails?.find(
                      (e: any) => e.pricingTypeName === selectBilling.toLowerCase()
                    )?.planAmount;
                    return (
                      <div
                        className={`border md:border-l-0 p-7 text-center flex flex-col justify-between  ${
                          pkg.planName == "Enterprise" && "bg-primary bg-opacity-20"
                        }`}
                      >
                        <div>
                          <div className={`text-base font-bold mb-4`}>
                            {pkg.planName == "Enterprise" ? "FOC Package" : pkg.planName}
                          </div>
                          {planAmount && planAmount > 0 ? (
                            <div className={`mb-6`}>
                              <div className="md:flex truncate flex-wrap md:gap-1 items-center justify-center ">
                                <span className={`font-bold text-lg`}>
                                  {pkg.currencyTypeName ? pkg.currencyTypeName : "SGD"}
                                </span>
                                <span className={`font-bold text-2xl`}>{planAmount}</span>
                                <span className={`text-gray-400 font-normal`}>
                                  {" "}
                                  / {selectBilling === "Monthly" ? "Month" : "Year"}
                                </span>
                              </div>
                              <div className="text-xs">Billed Monthly</div>
                            </div>
                          ) : (
                            <div className={`mb-6`}>
                              <div className="md:flex truncate flex-wrap md:gap-1 items-center justify-center ">
                                <span className={`font-bold text-lg`}>{"SGD"}</span>
                                <span className={`font-bold text-2xl`}>{"0"}</span>
                                <span className={`text-gray-400 font-normal`}> / Month</span>
                              </div>
                              <div className="text-xs italic">U.P. SGD 150.00</div>
                              <div className="text-xs">Billed Monthly</div>
                            </div>
                          )}
                        </div>
                        <div className="">
                          {planAmount && planAmount > 0 ? (
                            <>
                              <div
                                onClick={() => handleChoosePlan(pkg.encryptedId)}
                                className={`flex justify-center cursor-pointer hover:opacity-70 active:bg-gray-400 text-base py-3 px-5 bg-gray-300 text-gray-500 rounded-lg`}
                              >
                                Sign Up and Choose This Plan
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                onClick={() => handleChoosePlan("Freemium")}
                                className={`flex justify-center cursor-pointer hover:opacity-70 active:bg-gray-400 text-base py-3 px-5 bg-primary text-white rounded-lg`}
                              >
                                Sign Up and Choose This Plan
                              </div>
                              {mode && mode == "free" && <div className="text-xs pt-1.5 pb-0 mb-0 h-4"></div>}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/* <div className={`w-full grid grid-cols-1 gap-4 md:gap-0 md:grid-cols-4 sm:grid-cols-2 text-sm `}>
              <div className={`border border-pricingBorder flex flex-col justify-center p-7`}>
                <div className={`border-2 border-gray-400 text-center bg-white rounded-full text-black py-2 px-4`}>
                  Special Promotion
                </div>
              </div>
              <div className={`border border-pricingBorder flex flex-col justify-center items-center p-7`}>
                <div>Free to Try for 12 Months,</div>
                <div>Price Below Thereafter</div>
              </div>
              <div
                className={` md:border border-pricingBorder flex flex-col font-semibold text-lg justify-center p-7`}
              ></div>
              <div className={`md:border border-pricingBorder flex flex-col font-semibold text-lg justify-center p-7`}></div>
            </div> */}
              <div className={`w-full grid grid-cols-1 md:gap-0 md:grid-cols-5 sm:grid-cols-2 text-sm `}>
                <div
                  className={`border md:border-t-0 border-pricingBorder flex items-start flex-col text-base justify-center p-7`}
                >
                  Number of User
                </div>
                {packageList.length > 0 &&
                  packageList.map((pkg: any) => (
                    <>
                      {pkg.details.length > 0 &&
                        pkg.details
                          .filter((e: any) => e.menuName == "User Management")
                          .map((menu: any) => {
                            return (
                              <div
                                className={`border md:border-t-0 md:border-l-0 border-pricingBorder flex flex-col justify-center items-center p-7 gap-3 ${
                                  pkg.planName == "Enterprise" && "bg-primary bg-opacity-20"
                                }`}
                              >
                                {/* <div className={`flex justify-start font-semibold mb-1`}> */}
                                {menu.txUsage == 0 ? `1 User` : menu.txUsage > 1 ? `${menu.txUsage} Users` : `1 User`}
                                {/* </div> */}
                              </div>
                            );
                          })}
                    </>
                  ))}
              </div>
              <div className={`w-full grid grid-cols-1 md:gap-0 md:grid-cols-5 sm:grid-cols-2 text-sm `}>
                <div
                  className={`border md:border-t-0 border-pricingBorder flex items-start flex-col text-base justify-center p-7`}
                >
                  Entity
                </div>
                <div
                  className={`border md:border-t-0 md:border-l-0 border-pricingBorder flex justify-center items-center p-7 bg-primary bg-opacity-20`}
                >
                  1 Entity
                </div>
                <div
                  className={`border md:border-t-0 md:border-l-0 border-pricingBorder flex justify-center items-center p-7`}
                >
                  1 Entity
                </div>
                <div
                  className={`border md:border-t-0 md:border-l-0 border-pricingBorder flex justify-center items-center p-7`}
                >
                  1 Entity
                </div>
                <div
                  className={`border md:border-t-0 md:border-l-0 border-pricingBorder flex justify-center items-center p-7`}
                >
                  1 Entity
                </div>
              </div>
              <div
                className={`w-full grid grid-cols-1 md:gap-0 md:grid-cols-5 sm:grid-cols-2 text-sm bg-gray-200`}
              >
                <div
                  className={`border md:border-t-0 md:border-l-0 border-pricingBorder flex justify-center items-center p-7 bg-gray-200`}
                >
                  Module
                </div>
                <div
                  className={`border md:border-t-0 md:border-l-0 border-pricingBorder flex justify-center items-center p-7 bg-primary bg-opacity-20`}
                ></div>
                <div
                  className={`border md:border-t-0 md:border-l-0 border-pricingBorder flex justify-center items-center p-7 bg-gray-200`}
                ></div>
                <div
                  className={`border md:border-t-0 md:border-l-0 border-pricingBorder flex justify-center items-center p-7 bg-gray-200`}
                ></div>
                <div
                  className={`border md:border-t-0 md:border-l-0 border-pricingBorder flex justify-center items-center p-7 bg-gray-200`}
                ></div>
              </div>
              {tempMenuLists.length > 0 &&
                tempMenuLists.map((menu: any) => (
                  <div className={`w-full grid grid-cols-1 md:gap-0 md:grid-cols-5 sm:grid-cols-2 text-sm `}>
                    <div
                      className={`border  md:border-t-0 border-pricingBorder flex items-start gap-1 text-base justify-start p-7`}
                    >
                      {country == "sg" ? menu.label : menu.label.replace("InvoiceNow", "Peppol")}{" "}
                      {menu.description != "" ? (
                        <span className="text-sm text-darkOrange">{menu.description}</span>
                      ) : (
                        ""
                      )}
                    </div>
                    {packageList.length > 0 &&
                      packageList.map((pkg: any) => (
                        <div
                          className={`border md:border-t-0 md:border-l-0 border-pricingBorder flex justify-center font-semibold items-center p-4 ${
                            pkg.planName == "Enterprise" && "bg-primary bg-opacity-20"
                          }`}
                        >
                          {menu.menuName == "" ? (
                            <Icon type={IconType.TickIcon} className="w-10" />
                          ) : menu.menuName == "peppolTransactionNo" ? (
                            <span className="flex items-center">
                              {pkg.peppolTransactionNo > 0 ? pkg.peppolTransactionNo + " Documents" : "Unlimited"}
                            </span>
                          ) : menu.menuName == "emailTransactionNo" ? (
                            <span className="flex items-center">
                              {pkg.emailTransactionNo > 0 ? pkg.emailTransactionNo + " Documents" : "Unlimited"}
                            </span>
                          ) : menu.menuName == "c5TransactionNo" ? (
                            <>
                              {pkg.planName != "Enterprise" ? (
                                <>
                                  <Icon type={IconType.TickIcon} className="w-10" />
                                </>
                              ) : (
                                <>
                                  <span className="flex items-center">{"Unlimited"}</span>
                                </>
                              )}
                            </>
                          ) : pkg.details?.find((e: any) => e.menuName == menu.menuName) ? (
                            menu.showOutbound == true ? (
                              pkg.details?.find((e: any) => e.menuName == menu.menuName).txUsage > 0 ||
                              pkg.details?.find((e: any) => e.menuName == menu.subMenuName).txUsage > 0 ? (
                                pkg.planName != "Enterprise" ? (
                                  <div className="text-center">
                                    <span className="flex mb-1 justify-center">
                                      {(pkg.details?.find((e: any) => e.menuName == menu.menuName).txUsage
                                        ? pkg.details?.find((e: any) => e.menuName == menu.menuName).txUsage
                                        : 0) +
                                        (pkg.details?.find((e: any) => e.menuName == menu.subMenuName).txUsage
                                          ? pkg.details?.find((e: any) => e.menuName == menu.subMenuName).txUsage
                                          : 0)}{" "}
                                      Inbound / Outbound {menu.useMenuUnit == true ? menu.menuName + "s" : menu.unit}
                                    </span>
                                    {/* <span className="text-darkOrange">{"Inbound / Outbound "+ menu.unit}</span> */}
                                  </div>
                                ) : (
                                  <span className="flex items-center">Contact Us</span>
                                )
                              ) : (
                                <Icon type={IconType.TickIcon} className="w-10" />
                              )
                            ) : pkg.details?.find((e: any) => e.menuName == menu.menuName).txUsage > 0 ? (
                              pkg.planName != "Enterprise" ? (
                                <span className="flex items-center">
                                  {pkg.details?.find((e: any) => e.menuName == menu.menuName).txUsage}{" "}
                                  {menu.useMenuUnit == true ? menu.unit : ""}
                                </span>
                              ) : (
                                <span className="flex items-center">Contact Us</span>
                              )
                            ) : (
                              <>
                                {pkg.planName == "Enterprise" ? (
                                  menu.menuName == "Quotation" ||
                                  menu.menuName == "Purchase Order" ||
                                  menu.menuName == "Purchase Requisition" ||
                                  menu.menuName == "Delivery Order" ? (
                                    "Add-ons"
                                  ) : (
                                    <Icon type={IconType.TickIcon} className="w-10" />
                                  )
                                ) : (
                                  <Icon type={IconType.TickIcon} className="w-10" />
                                )}
                              </>
                            )
                          ) : menu.subMenuName != "" ? (
                            pkg.details?.find((e: any) => e.menuName == menu.subMenuName) ? (
                              pkg.details?.find((e: any) => e.menuName == menu.subMenuName).txUsage > 0 ? (
                                <span className="flex items-center">
                                  {pkg.details?.find((e: any) => e.menuName == menu.subMenuName).txUsage} {menu.subUnit}
                                </span>
                              ) : (
                                <>
                                  {pkg.planName != "Enterprise" ? (
                                    <Icon type={IconType.TickIcon} className="w-10" />
                                  ) : (
                                    <svg
                                      width="30"
                                      height="29"
                                      viewBox="0 0 25 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M18 11H7C6.71875 11 6.5 11.25 6.5 11.5V12.5C6.5 12.7812 6.71875 13 7 13H18C18.25 13 18.5 12.7812 18.5 12.5V11.5C18.5 11.25 18.25 11 18 11Z"
                                        fill="#8B909A"
                                      />
                                    </svg>
                                  )}
                                </>
                              )
                            ) : (
                              <svg
                                width="30"
                                height="29"
                                viewBox="0 0 25 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18 11H7C6.71875 11 6.5 11.25 6.5 11.5V12.5C6.5 12.7812 6.71875 13 7 13H18C18.25 13 18.5 12.7812 18.5 12.5V11.5C18.5 11.25 18.25 11 18 11Z"
                                  fill="#8B909A"
                                />
                              </svg>
                            )
                          ) : (
                            <svg
                              width="30"
                              height="29"
                              viewBox="0 0 25 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 11H7C6.71875 11 6.5 11.25 6.5 11.5V12.5C6.5 12.7812 6.71875 13 7 13H18C18.25 13 18.5 12.7812 18.5 12.5V11.5C18.5 11.25 18.25 11 18 11Z"
                                fill="#8B909A"
                              />
                            </svg>
                          )}
                        </div>
                      ))}
                  </div>
                ))}
            </div>
          </>
        ) : (
          <LoaderV2 visibility={isLoading} colour={colors.tertiary} loader={true} />
        )}
        <div className="pt-8">
          <div className="text-lg font-normal pb-8">
            Enhance your subscription with our optional add-ons, available for an additional charge. Tailor your
            experience to fit your needs. Contact us for more details.
          </div>
          <div className="font-bold text-2xl md:text-3xl font-poppins">Subscription Changes?</div>
          <div className="text-lg font-normal pt-8">
            We're here to help. If you're an existing customer looking to upgrade, downgrade, or make other changes to
            your subscription, please reach out to us. We're happy to assist with any questions or requests you may
            have.
          </div>
        </div>
      </div>
    </>
  );
  return <HomeLayout showHeader={true} showFooter={true} mainContent={mainContentDesktop} currentPage="Freemium" />;
};
