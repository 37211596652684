import {
    Notification,
    Dashboard,
    PurchaseRequisition,
    PurchaseOrder,
    GoodsReceipt,
    InvoiceReceived,
    Contract,
    Reports,
    Configuration,
    Quotation,
    Payment,
    Claims,
    Budget,
    CNReceived,
    CreditNote,
    POReceived,
    StatementOfAccount,
    Timesheet,
    DeliveryOrder,
    UserManagement,
    Chat,
    Analytics,
    LaptopCode,
    Sparkle,
    Atom,
    BullsEyePointer,
    FileInvDollar,
    Receipt,
    AdminManagement, GRNReceived, Vendor, Invoice, Workflow, Analytic,
    CheckCircle,
    TickIcon
} from "../../assets/svg";
import "./icon.css";

export enum IconType {
    Notification = "Notification",
    POReceived = "POReceived",
    Chat = "Chat",
    APDashboard = "APDashboard",
    ARDashboard = "ARDashboard",
    PurchaseRequisition = "PurchaseRequisition",
    PurchaseOrder = "PurchaseOrder",
    InvoiceReceived = "InvoiceReceived",
    Reports = "Reports",
    StatementOfAccount = "StatementOfAccount",
    Configuration = "Configuration",
    Contract = "Contract",
    UserManagement = "UserManagement",
    DeliveryOrder = "DeliveryOrder",
    vendorManagement = "vendorManagement",
    Vendor = "Vendor",
    Invoice = "Invoice",
    Quotation = "Quotation",
    Payment = "Payment",
    Claims = "Claims",
    GoodsReceipt = "GoodsReceipt",
    GRNReceived = "GRNReceived",
    Timesheet = "Timesheet",
    Budget = "Budget",
    CNReceived = "CNReceived",
    CreditNote = "CreditNote",
    AdminManagement = "AdminManagement",
    Workflow = "Workflow",
    Analytic = "Analytic",
    Analytics = "Analytics",
    LaptopCode = "LaptopCode",
    Sparkle = "Sparkle",
    Atom = "Atom",
    BullsEyePointer = "BullsEyePointer",
    FileInvDollar = "FileInvDollar",
    Receipt = "Receipt",
    TickIcon = "TickIcon",
    CheckCircle= "CheckCircle"
}

export enum IconVariant {
    inline = "inline",
    clickable = "clickable",
}

interface IconProps {
    /**
     * Icon appearance
     */
    type: IconType;
    /**
     * Optional colour customisation
     */
    colour?: string;
    fill?: string;
    /**
     * Optional size customisation
     */
    size?: number;
    /**
     * Notification (if this is >1, will show up as notification)
     */
    notification?: number;
    /**
     * If items is inline or clickable item
     */
    variant?: IconVariant;
    /**
     * Optional class name
     */
    className?: string;
    /**
     * Optional click handler
     */
    onClick?: () => void;
    isNotification?: boolean;
}

export const Icon = ({ type, colour, fill, size = 24, className = "", variant, ...props }: IconProps) => {
    const translate = `scale(${size / 24})`;
    // console.log("get icon coulor", colour)
    const getIconType = () => {
        switch (type) {
            case IconType.Notification:
                return <Notification fill={fill} className={className} transform={translate} />;
            case IconType.APDashboard:
                return <Dashboard fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.ARDashboard:
                return <Dashboard fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.PurchaseRequisition:
                return <PurchaseRequisition fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.PurchaseOrder:
                return <PurchaseOrder fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.GRNReceived:
                return <GRNReceived fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Timesheet:
                return <Timesheet fill={fill} className={className} transform={translate} />;
            case IconType.InvoiceReceived:
                return <InvoiceReceived fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Contract:
                return <Contract fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Reports:
                return <Reports fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.StatementOfAccount:
                return <StatementOfAccount fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Configuration:
                return <Configuration fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.UserManagement:
                return <UserManagement fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.DeliveryOrder:
                return <DeliveryOrder fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Vendor:
                return <Vendor fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Invoice:
                return <Invoice fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Quotation:
                return <Quotation fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Payment:
                return <Payment fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Claims:
                return <Claims fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.CNReceived:
                return <CNReceived fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Chat:
                return <Chat fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.GoodsReceipt:
                return <GoodsReceipt fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Budget:
                return <Budget fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.CreditNote:
                return <CreditNote fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.POReceived:
                return <POReceived fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.AdminManagement:
                return <AdminManagement fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Workflow:
                return <Workflow fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Analytic:
                return <Analytic fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Analytics:
                return <Analytics fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.LaptopCode:
                return <LaptopCode fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Sparkle:
                return <Sparkle fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Atom:
                return <Atom fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.BullsEyePointer:
                return <BullsEyePointer fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.Receipt:
                return <Receipt fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.FileInvDollar:
                return <FileInvDollar fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.TickIcon:
                return <TickIcon fill={fill} className={className} color={colour} transform={translate} />;
            case IconType.CheckCircle:
                return <CheckCircle fill={fill} className={className} color={colour} transform={translate} />;

            default:
                return <></>;
        }
    };

    if (props.isNotification)
        return (
            <button onClick={variant === IconVariant.clickable ? props.onClick : undefined}>
                <div className="cursor-pointer">
                    <strong className="relative inline-flex items-center px-2.5 py-1.5 text-xs font-medium">
                        <span
                            className={[
                                "absolute -top-[8px] -right-[5px] w-6 rounded-full bg-red-600 flex justify-center items-center items",
                                !props.notification ? "hidden" : "block",
                            ].join(" ")}
                        >
                            <span className="text-white">{props.notification}</span>
                        </span>
                        <span className={`ml-1.5 text-${colour}`}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="currentColor"
                                className="bi bi-bell"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                            </svg>
                        </span>
                    </strong>
                </div>
            </button>
        );
    else if (variant === IconVariant.inline) return <>{getIconType()}</>;
    else return <button
        onClick={variant === IconVariant.clickable ? props.onClick : undefined}>{getIconType()}</button>;
};
