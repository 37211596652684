import { useEffect, useState } from "react";
import { HomeLayout } from "../layouts/HomeLayout";
import LogoMark5 from "../assets/image/AgileApVector-1.png";
import LogoMark6 from "../assets/image/AgileAPVector-2.png";
import InvoiceNowLGLogo from "../assets/image/InvoiceNow_Logo.png";
import InvoiceNowBlackLogo from "../assets/image/InvoiceNow_Black.png";
import dashboardImg from "../assets/image/Dashboard-2.png";
import MetatagHelmet from "../components/MetatagHelmet";
import { useNavigate, useParams } from "react-router-dom";
import gstInvoiceNow from "../assets/image/gst-invoicenow.png";
import fastBillingInvoiceNow from "../assets/image/fast-billing.png";
import cleanDataInvoiceNow from "../assets/image/clean-data.png";
import automatedProcessInvoiceNow from "../assets/image/automated-processing.png";
import futureProofingInvoiceNow from "../assets/image/future-proofing.png";
import { FaCheck } from "react-icons/fa";
import { Helper } from "../utils/helpers";
import Content from "../assets/image/Content.png";
import * as React from "react";

const freqQAndAList = [
  {
    question: "How do I sign up for Peppol (InvoiceNow) e-invoicing? ",
    answer: "Simply approach us at sales@agileap.com or info@activants.com",
    index: 0,
  },
  {
    question: "Do I need to register my company for a Peppol ID? If yes, how can I do it? ",
    answer:
      "Certainly, your company must be registered to send and receive Peppol e-invoices. Registration will be handled by Activants (Tickstar Access Point) upon your sign-up. ",
    index: 1,
  },
  {
    question:
      "Given that InvoiceNow utilises the European-based Peppol network, can it still function effectively in Singapore? ",
    answer:
      "Indeed, there is a distinction. Online invoicing typically involves sending a scanned copy of the invoice or a PDF file to the recipient via email. This method relies on manual data entry, which is inherently susceptible to human error. <br><br> On the other hand, InvoiceNow represents a form of e-invoicing wherein invoices are digitally generated within a supplier's financial system. They are then electronically transmitted through the network to the recipient's financial system, where they are processed automatically, without human intervention. This ensures a more secure, accurate, and reliable transmission of data. ",
    index: 2,
  },
  {
    question: "What exactly does e-invoicing entail, and is it secure? ",
    answer:
      "E-invoicing is an automated procedure enabling companies in Singapore to electronically create, send, and receive invoices via specialised software or solutions. This eliminates the necessity for printing and posting traditional paper invoices, thereby streamlining the entire payment process.  <br><br> Conducting e-invoicing transactions on the Peppol network directly links sellers and purchasers. Additionally, e-invoices are transmitted using electronic standards and delivered directly to your Peppol-registered customers in the network, employing industry-standard security protocols like SSL encryption to safeguard personal details from interception by third parties during transmission. ",
    index: 3,
  },
  {
    question: "Can IRAS assess my e-invoices? ",
    answer:
      "No, according to current legal regulations, IRAS cannot access invoices. Nevertheless, you are still required to adhere to any relevant tax regulations stipulated by IRAS. ",
    index: 4,
  },
  {
    question: "Is e-invoicing compulsory in Singapore?  ",
    answer:
      "Although e-invoicing is not obligatory in Singapore, the nation has taken numerous measures to encourage organisations to participate in the Peppol network via the country's dedicated InvoiceNow platform. ",
    index: 5,
  },
];

export default function InvoiceNowV2() {
  const { country } = useParams();
  const [clickedIndex, setClickedIndex] = useState(0);
  const navigate = useNavigate();
  const [appUrl, setAppUrl] = useState(process.env.REACT_APP_URL ? process.env.REACT_APP_URL : "");
  const [mainUrl, setMainUrl] = useState(process.env.REACT_APP_IMAGE_URL ? process.env.REACT_APP_IMAGE_URL : "https://agileap.com/");
  useEffect(() => {
    if (country == "my") {
      navigate(`/${country}/e-invoicing`);
    }
  }, []);

  const [questionQuery, setQuestionQuery] = useState<any>({});
  const [changes, setChanges] = useState<any>();
  const handleChangeQuestion = (question: string) => {
    let questionQueries = questionQuery;
    if (questionQueries[question] === undefined) {
      questionQueries[question] = true;
    } else {
      questionQueries[question] = !questionQueries[question];
    }
    setQuestionQuery(questionQueries);
    setChanges(!changes);
  };
  const body =
    "Hello,\n\nI am interested in the AgileAP. I would like to request for a demo. \nInfomation: \nTitle: \nFull Name: \nCompany Name: \nEmail: ";

  const handleClick = () => {
    window.open(`https://wa.me/6580880170/?text=${encodeURIComponent(body)}`, "", "noreferrer");
  };
  const handleClickC5 = () => {
    window.open(mainUrl+"freemium");
  };

  const handleClickIMDA = () => {
    window.open(
      "https://www.imda.gov.sg/how-we-can-help/nationwide-e-invoicing-framework/invoicenow-technical-playbook"
    );
  };

  const mainContentDesktop = (
    <>
      <MetatagHelmet
        title="InvoiceNow | AgileAP - Automate Accounts Payable and Receivables"
        description="AgileAP is an IMDA accredited solution provider for InvoiceNow. With AgileAP, e-invoices, e-purchase orders and e-credit notes can be exchanged seamlessly and nearly instantaneously to any buyers and suppliers through the InvoiceNow network."
        url={window.location.href}
      />

      <div className="flex flex-col w-full items-center lg:gap-y-16 gap-y-8">
        <section
          style={{ backgroundImage: `url(${LogoMark5})`, backgroundRepeat: "no-repeat", backgroundSize: "inherit" }}
          className="flex relative flex-col w-full pt-24 text-black bg-gray-50"
        >
          <div className="z-20 flex justify-center">
            <div className="flex flex-col justify-center gap-y-6 bg-gray-50 bg-opacity-60 md:bg-opacity-0 s p-2 sm:p-4 rounded-md w-fit max-w-[92vw]">
              <label className="text-black font-bold text-2xl lg:text-5xl md:text-5xl text-center">
                Effortless E-Invoicing with
              </label>
              <div className={`flex justify-center `}>
                <img src={InvoiceNowBlackLogo} className="w-3/4 lg:w-5/12 xl:w-4/12 max-w-[90rem] -mt-8" />
              </div>
              <p className="text-base md:text-lg lg:text-lg text-center px-12 flex-wrap">
                Seamless, IRAS GST InvoiceNow compliant e-invoicing with <br /> AgileAP-cut costs, eliminate errors, and
                get paid faster.
              </p>
              <div className={`flex flex-col justify-center gap-x-8 items-center gap-y-4`}>
                {/*<button
                    onClick={() => {
                        Helper.CallAnalyticEvent(window.location.pathname, "Click Call for a free demo Button");
                    }}
                    type="button"
                    className="font-medium text-white bg-darkBlue  rounded-md hover:ring-0 hover:outline-none hover:bg-opacity-90 active:bg-opacity-70 text-base px-4 py-2 text-center mr-3 md:mr-0 "
                >
                    Login
                </button>
                <button
                    onClick={() => {
                        Helper.CallAnalyticEvent(window.location.pathname, "Click Call for a free demo Button");
                        handleClick();
                    }}
                    type="button"
                    className="font-medium text-white bg-darkBlue rounded-md hover:ring-0 hover:outline-none hover:bg-opacity-90 active:bg-opacity-70 text-base px-4 py-2 text-center mr-3 md:mr-0 "
                >
                    Request a Demo
                </button>*/}
                <div
                  onClick={handleClickC5}
                  className={`py-2 cursor-pointer flex flex-col space-y-2 justify-center text-center px-12 bg-darkBlue text-white rounded-md`}
                >
                  <label className={`text-base cursor-pointer`}>Claim your C5 FOC Package Today!</label>
                  <label className={`text-xs text-gray-50 cursor-pointer`}>
                    C5 FOC Package only available for GST-registered businesses.
                  </label>
                </div>
                <div className={`font-[inter] text-[12px] text-center`}>
                  <i>
                    This offer is valid from <b className="font-bold">1 April 2025 to 31 March 2027</b>, in line with
                    IMDA's initiative.
                  </i>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={`flex relative`}>
              <div
                className={`absolute right-0 bottom-0 opacity-30 sm:opacity-50 md:opacity-70 lg:opacity-90 xl:opacity-100`}
              >
                <img src={LogoMark6} className={``} />
              </div>
              <div className={`flex justify-center mt-12 md:mt-24 lg:mt-24 `}>
                <img src={dashboardImg} className={`w-11/12 sm:w-10/12 bg-white z-10 rounded-md`} />
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col w-10/12 py-6 gap-y-6 justify-center">
          <div className="flex flex-col">
            {/* <label className='text-primary text-sm font-semibold'>E-INVOICING IN SINGAPORE </label> */}
            <label className="lg:text-3xl text-xl font-black text-black text-center">
              WHAT IS <span className="text-purple">PEPPOL (INVOICENOW) </span>E-INVOICING?
            </label>
          </div>
          <div className="flex flex-col gap-y-8 justify-center">
            <div className="flex flex-col gap-y-10 w-full text-base ">
              <p>
                InvoiceNow is a nationwide e-invoicing network that enables companies of all sizes to process invoices
                in a more efficient manner, reducing business costs and shortening payment cycles. InvoiceNow operates
                on the open standard Peppol framework, which directly transmits e-invoices in a standard digital format
                across different finance systems.{" "}
              </p>
              <div className={`rounded-md`}>
                <div className=" flex flex-col items-center">
                  <div className="w-full flex justify-center">
                    <iframe
                      className="w-full max-w-[100vw] lg:max-w-[70vw] h-[350px] md:h-[440px] lg:h-[460px] xl:h-[740px] rounded-md"
                      src="https://www.youtube.com/embed/ypoBEFSCfjk?si=tmK3znwt-dOwarnN"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className={`flex flex-col md:flex-row justify-between gap-8`}>
                <p>
                  When both the sender and recipient uses AgileAP, e-invoices, e-purchase orders and e-credit notes can
                  be exchanged seamlessly and nearly instantaneously, unlike the traditional method of sending PDF files
                  or online invoices via email.This facilitates smoother invoicing, quicker payments, and offers a more
                  environmentally friendly solution for both Small and Medium Enterprises (SMEs) and Large Enterprises
                  (LEs).
                </p>
                <p>
                  Furthermore, starting from 01 November 2025, GST-registered businesses are mandated to utilise
                  InvoiceNow solutions to transmit invoice data to the Inland Revenue Authority of Singapore (IRAS).
                  This initiative aligns with national efforts to promote InvoiceNow adoption and integrates tax
                  processes into businesses' accounting and payroll systems as part of ongoing digital transformation.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col w-10/12 py-6 gap-y-6">
          <div className={`flex flex-col md:flex-row justify-between gap-y-8 gap-x-24`}>
            <div className={`flex flex-col gap-y-8 my-8`}>
              <label className="lg:text-3xl text-2xl font-black text-black space-y-2">
                <p>GST InvoiceNow Requirement</p>
                <p>for GST-Registered</p>
                <p>Business</p>
              </label>
              <p>
                On <b>15 April 2024</b>, the <b>Inland Revenue Authority of Singapore (IRAS)</b> announced a phased
                adoption plan for InvoiceNow to improve productivity, enhance compliance, and reduce business costs. In
                collaboration with <b>IMDA</b>, IRAS will require <b>GST-registered businesses</b> to transmit invoice
                data using <b>InvoiceNow-Ready Solutions</b> via the InvoiceNow network.
              </p>
              <p className={`my-6 flex flex-col justify-center gap-y-4`}>
                <div
                  onClick={handleClickIMDA}
                  className={`py-2 cursor-pointer flex justify-center w-full text-center px-6 border-2 border-secondary rounded-md`}
                >
                  Learn More
                </div>
                <div
                  onClick={handleClickC5}
                  className={`py-2 cursor-pointer flex flex-col space-y-2 justify-center w-full text-center px-6 bg-darkBlue text-white rounded-md`}
                >
                  <label className={`text-base cursor-pointer`}>Claim your C5 FOC Package Today!</label>
                  <label className={`text-xs text-gray-50 cursor-pointer`}>
                    C5 FOC Package only available for GST-registered businesses.
                  </label>
                </div>
                <div className={`font-[inter] text-[12px] text-center`}>
                  <i>
                    This offer is valid from <b className="font-bold">1 April 2025 to 31 March 2027</b>, in line with
                    IMDA's initiative.
                  </i>
                </div>
              </p>
            </div>
            <div className={``}>
              <img src={gstInvoiceNow} className="bg-[#f6f7f8]" />
            </div>
          </div>
        </section>
        <section className="bg-gray-100 flex justify-center">
          <div className="flex flex-col w-10/12 py-24 gap-y-6 bg-gray-100">
            <div className="flex flex-col justify-center gap-y-4 mb-8">
              <label className="lg:text-3xl text-xl font-black text-black text-center">
                WHY USE <span className="text-purple">INVOICENOW</span>?
              </label>
              <div className={`text-center space-y-1`}>
                <p>By adopting InvoiceNow through AgileAP, businesses can ensure seamless compliance,</p>
                <p>maintain a competitive edge, and stay future ready in an increasingly digital economy.</p>
              </div>
            </div>
            <div className="flex flex-col gap-y-6">
              <div className="flex flex-col lg:flex-row gap-y-8 lg:gap-x-6 w-full">
                <div className={`w-full lg:w-7/12 bg-white rounded-md py-4`}>
                  <div className={`flex justify-center`}>
                    <img src={fastBillingInvoiceNow} className="bg-white h-[200px] md:h-[240px] object-cover" />
                  </div>
                  <div className={` flex flex-col gap-y-4 my-6 px-4`}>
                    <label className="text-base md:text-lg font-black text-black">Fast Billing</label>
                    <ul className="list-disc space-y-1 px-12 ">
                      <li>Instant transmission of e-invoices without postal or email delays. </li>
                      <li>
                        Automated invoice generation from the Accounts Payable system, utilizing pre-filled customer and
                        product data for speed and accuracy.{" "}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`w-full lg:w-5/12 bg-white rounded-md py-4`}>
                  <div className={`flex justify-center`}>
                    <img src={futureProofingInvoiceNow} className="bg-white h-[200px] md:h-[240px] object-cover" />
                  </div>
                  <div className={` flex flex-col gap-y-4 my-6 px-4`}>
                    <label className="text-base md:text-lg font-black text-black">Future-Proofing</label>
                    <ul className="list-disc space-y-1 px-12 ">
                      <li>
                        Prepare for the phasing out of Vendors@Gov, as InvoiceNow will become the default e-invoice
                        submission channel for all government vendors.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-y-8 lg:gap-x-6 w-full">
                <div className={`w-full lg:w-5/12 bg-white rounded-md py-4`}>
                  <div className={`flex justify-center`}>
                    <img src={cleanDataInvoiceNow} className="bg-white h-[200px] md:h-[240px] object-cover" />
                  </div>
                  <div className={` flex flex-col gap-y-4 my-6 px-4`}>
                    <label className="lg:text-xl text-lg font-black text-black">Clean Data</label>
                    <ul className="list-disc space-y-1 px-12 ">
                      <li>Standardized Peppol format minimizes manual intervention and reduces human errors. </li>
                      <li>
                        Eliminates the need for manual data entry or document scanning, lowering the risk of fraud and
                        manipulation.{" "}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`w-full lg:w-7/12 bg-white rounded-md py-4`}>
                  <div className={`flex justify-center`}>
                    <img src={automatedProcessInvoiceNow} className="bg-white h-[200px] md:h-[240px] object-cover" />
                  </div>
                  <div className={` flex flex-col gap-y-4 my-6 px-4`}>
                    <label className="lg:text-xl text-lg font-black text-black">Automated Processing</label>
                    <ul className="list-disc space-y-1 px-12 ">
                      <li>Straight-through processing with AI-driven anomaly and fraud detection. </li>
                      <li>2-way or 3-way invoice matching for quicker verification and approval. </li>
                      <li>Real-time dashboards and notifications for better monitoring and reduced payment delays. </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-y-8 lg:gap-x-6 w-full">
                <div className={`w-full bg-white rounded-md py-4`}>
                  <div className={` flex flex-col gap-y-4 my-6 px-4`}>
                    <label className="text-base md:text-lg font-black text-black">Tax Compliance</label>
                    <ul className="list-disc space-y-1 px-12 ">
                      <li>
                        Fully compliant with IRAS requirements for GST-registered businesses as part of the phased GST
                        InvoiceNow Requirement.{" "}
                      </li>
                      <li>Seamless integration with accounting and payroll systems to simplify tax reporting. </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col w-full py-8 lg:pl-24 lg:px-24 px-8 gap-y-6 bg-white">
          <div className="flex flex-col justify-center gap-y-4">
            <label className="lg:text-3xl text-xl font-black text-black text-center">Frequently Ask Questions</label>
            <div className={`text-center space-y-1`}>
              <p>If there are questions you want to ask, we will answer all your questions.</p>
            </div>
          </div>
          <div className={`w-full flex flex-col py-8 justify-center px-8 md:px-24 lg:px-24 `}>
            <div className={`border-b border-gray-300 py-6 flex flex-col gap-y-8`}>
              <div
                className={`flex justify-between items-center gap-x-8 cursor-pointer`}
                onClick={() => handleChangeQuestion("q-1")}
              >
                <label
                  className={`text-base md:text-lg cursor-pointer ${
                    questionQuery["q-1"] === true ? "text-primary" : "text-secondary"
                  }`}
                >
                  What is InvoiceNow?
                </label>
                {questionQuery["q-1"] === true ? (
                  <span className={`text-primary text-2xl md:text-xl cursor-pointer`}>&#8722;</span>
                ) : (
                  <span className={`text-black text-2xl md:text-3xl cursor-pointer`}>&#43;</span>
                )}
              </div>
              <p className={`text-secondary ${questionQuery["q-1"] === true ? "" : "hidden"}`}>
                InvoiceNow is Singapore’s <b>nationwide e-invoicing framework</b>, built on the <b>Peppol network</b>,
                enabling businesses to <b>send and receive invoices digitally between accounting systems</b>. It
                eliminates manual data entry, reduces errors, and improves payment efficiency.
              </p>
            </div>
            <div className={`border-b border-gray-300 py-6 flex flex-col gap-y-8 `}>
              <div
                className={`flex justify-between items-center gap-x-8 cursor-pointer`}
                onClick={() => handleChangeQuestion("q-2")}
              >
                <label
                  className={`text-base md:text-lg cursor-pointer ${
                    questionQuery["q-2"] === true ? "text-primary" : "text-secondary"
                  }`}
                >
                  What is the GST InvoiceNow Requirement?
                </label>
                {questionQuery["q-2"] === true ? (
                  <span className={`text-primary text-2xl md:text-xl cursor-pointer`}>&#8722;</span>
                ) : (
                  <span className={`text-black text-2xl md:text-3xl cursor-pointer`}>&#43;</span>
                )}
              </div>
              <p className={`text-secondary ${questionQuery["q-2"] === true ? "" : "hidden"}`}>
                On <b>April 15, 2024</b>, the <b>Inland Revenue Authority of Singapore (IRAS)</b> announced a phased
                adoption of <b>InvoiceNow for GST-registered businesses</b>. This requires businesses to{" "}
                <b>transmit invoice data to IRAS via the InvoiceNow network</b> using InvoiceNow-Ready Solutions.{" "}
              </p>
            </div>
            <div className={`border-b border-gray-300 py-6 flex flex-col gap-y-8`}>
              <div
                className={`flex justify-between items-center gap-x-8 cursor-pointer`}
                onClick={() => handleChangeQuestion("q-3")}
              >
                <label
                  className={`text-base md:text-lg cursor-pointer ${
                    questionQuery["q-3"] === true ? "text-primary" : "text-secondary"
                  }`}
                >
                  When does the GST InvoiceNow Requirement take effect?
                </label>
                {questionQuery["q-3"] === true ? (
                  <span className={`text-primary text-2xl md:text-xl cursor-pointer`}>&#8722;</span>
                ) : (
                  <span className={`text-black text-2xl md:text-3xl cursor-pointer`}>&#43;</span>
                )}
              </div>
              <p className={`text-secondary ${questionQuery["q-3"] === true ? "" : "hidden"}`}>
                <ul className="list-disc space-y-2 px-4">
                  <li>
                    <b>November 1, 2025</b> – Newly incorporated companies that voluntarily register for GST must use
                    InvoiceNow.{" "}
                  </li>
                  <li>
                    <b>April 1, 2026</b> – All new voluntary GST-registrants must comply.{" "}
                  </li>
                  <li>
                    <b>May 1, 2025</b> – Soft launch for early adopters (existing GST-registered businesses or
                    businesses applying for GST registration after May 1, 2025).
                  </li>
                  <li>
                    Future phases will <b>extend the requirement to all GST-registered businesses</b>.
                  </li>
                </ul>
              </p>
            </div>
            <div className={`border-b border-gray-300 py-6 flex flex-col gap-y-8`}>
              <div
                className={`flex justify-between items-center gap-x-8 cursor-pointer`}
                onClick={() => handleChangeQuestion("q-4")}
              >
                <label
                  className={`text-base md:text-lg cursor-pointer ${
                    questionQuery["q-4"] === true ? "text-primary" : "text-secondary"
                  }`}
                >
                  {" "}
                  Why should businesses adopt InvoiceNow?
                </label>
                {questionQuery["q-4"] === true ? (
                  <span className={`text-primary text-2xl md:text-xl cursor-pointer`}>&#8722;</span>
                ) : (
                  <span className={`text-black text-2xl md:text-3xl cursor-pointer`}>&#43;</span>
                )}
              </div>
              <p className={`text-secondary ${questionQuery["q-4"] === true ? "" : "hidden"}`}>
                <ul className="list-disc space-y-2 px-4">
                  <li>Faster Payments – Digital invoices speed up processing. </li>
                  <li>Reduced Errors – Eliminates manual data entry. </li>
                  <li>Regulatory Compliance – Meets IRAS tax requirements. </li>
                  <li>Cost Savings – Lowers administrative expenses. </li>
                  <li>Secure Transactions – Uses Peppol-certified access points. </li>
                </ul>
              </p>
            </div>
            <div className={`border-b border-gray-300 py-6 flex flex-col gap-y-8`}>
              <div
                className={`flex justify-between items-center gap-x-8 cursor-pointer`}
                onClick={() => handleChangeQuestion("q-5")}
              >
                <label
                  className={`text-base md:text-lg cursor-pointer ${
                    questionQuery["q-5"] === true ? "text-primary" : "text-secondary"
                  }`}
                >
                  Where can businesses get support for InvoiceNow adoption?
                </label>
                {questionQuery["q-5"] === true ? (
                  <span className={`text-primary text-2xl md:text-xl cursor-pointer`}>&#8722;</span>
                ) : (
                  <span className={`text-black text-2xl md:text-3xl cursor-pointer`}>&#43;</span>
                )}
              </div>
              <p className={`text-secondary ${questionQuery["q-5"] === true ? "" : "hidden"}`}>
                You can reach out to our sales team at <b>sales@agileap.com</b> or message us on WhatsApp at{" "}
                <b>+65 8088 0170</b> to discuss how we can support your business.
              </p>
            </div>
            <div
              className={`border-b border-gray-300 py-6 flex flex-col gap-y-8 cursor-pointer`}
              onClick={() => handleChangeQuestion("q-6")}
            >
              <div className={`flex justify-between items-center gap-x-8`}>
                <label
                  className={`text-base md:text-lg cursor-pointer ${
                    questionQuery["q-6"] === true ? "text-primary" : "text-secondary"
                  }`}
                >
                  What is AgileAP?
                </label>
                {questionQuery["q-6"] === true ? (
                  <span className={`text-primary text-2xl md:text-xl cursor-pointer`}>&#8722;</span>
                ) : (
                  <span className={`text-black text-2xl md:text-3xl cursor-pointer`}>&#43;</span>
                )}
              </div>
              <p className={`text-secondary ${questionQuery["q-6"] === true ? "" : "hidden"}`}>
                AgileAP is an <b>InvoiceNow-Ready Solution Provider (IRSP)</b> and{" "}
                <b>accounts payable and receivable automation solution</b> that helps businesses{" "}
                <b>integrate with InvoiceNow</b> effortlessly. It enables{" "}
                <b>seamless e-invoicing, tax compliance, and cross-border transactions</b> while ensuring faster invoice
                processing.
              </p>
            </div>
            <div
              className={`border-b border-gray-300 py-6 flex flex-col gap-y-8 cursor-pointer`}
              onClick={() => handleChangeQuestion("q-7")}
            >
              <div className={`flex justify-between items-center gap-x-8`}>
                <label
                  className={`text-base md:text-lg cursor-pointer ${
                    questionQuery["q-7"] === true ? "text-primary" : "text-secondary"
                  }`}
                >
                  How can businesses get onboarded to InvoiceNow with AgileAP?
                </label>
                {questionQuery["q-7"] === true ? (
                  <span className={`text-primary text-2xl md:text-xl cursor-pointer`}>&#8722;</span>
                ) : (
                  <span className={`text-black text-2xl md:text-3xl cursor-pointer`}>&#43;</span>
                )}
              </div>
              <p className={`text-secondary ${questionQuery["q-7"] === true ? "" : "hidden"}`}>
                <ul className={`space-y-2`}>
                  <li>
                    <span className={`flex gap-2 font-bold`}>
                      <FaCheck className={`text-primary text-base md:text-lg`} /> Step 1: Register for InvoiceNow
                    </span>
                    <ul className="list-disc space-y-2 px-8 py-4">
                      <li>Check if your accounting software is InvoiceNow-ready. </li>
                      <li>If not, onboard with an InvoiceNow Ready service provider certified like AgileAP. </li>
                    </ul>
                  </li>
                  <li>
                    <span className={`flex gap-2 font-bold`}>
                      <FaCheck className={`text-primary text-base md:text-lg`} /> Step 2: Set Up AgileAP
                    </span>
                    <ul className="list-disc space-y-2 px-8 py-4">
                      <li>Sign up with AgileAP for seamless InvoiceNow integration.</li>
                      <li>Configure your system to send and receive e-invoices via the InvoiceNow network. </li>
                      <li>Ensure compliance with IRAS GST InvoiceNow requirements. </li>
                    </ul>
                  </li>
                  <li>
                    <span className={`flex gap-2 font-bold`}>
                      <FaCheck className={`text-primary text-base md:text-lg`} /> Step 3: Start Transacting
                    </span>
                    <ul className="list-disc space-y-2 px-8 py-4">
                      <li>Use AgileAP to send, receive, and track invoices digitally. </li>
                      <li>Ensure tax compliance by transmitting invoice data to IRAS automatically. </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </div>
            <div className={`border-b border-gray-300 py-6 flex flex-col gap-y-8`}>
              <div
                className={`flex justify-between items-center gap-x-8 cursor-pointer`}
                onClick={() => handleChangeQuestion("q-8")}
              >
                <label
                  className={`text-base md:text-lg cursor-pointer ${
                    questionQuery["q-8"] === true ? "text-primary" : "text-secondary"
                  }`}
                >
                  {" "}
                  How do businesses send and receive documents using AgileAP?{" "}
                </label>
                {questionQuery["q-8"] === true ? (
                  <span className={`text-primary text-2xl md:text-xl cursor-pointer`}>&#8722;</span>
                ) : (
                  <span className={`text-black text-2xl md:text-3xl cursor-pointer`}>&#43;</span>
                )}
              </div>
              <p className={`text-secondary ${questionQuery["q-8"] === true ? "" : "hidden"}`}>
                <ul className={`space-y-2`}>
                  <li>
                    <span className={`flex gap-2 font-bold`}>
                      <FaCheck className={`text-primary text-base md:text-lg`} /> Sending Invoices via AgileAP
                    </span>
                    <ul className="list-disc space-y-2 px-8 py-4">
                      <li>Enter the invoice details in AgileAP. </li>
                      <li>
                        AgileAP automatically formats and transmits the invoice via the InvoiceNow (Peppol) network.{" "}
                      </li>
                      <li>The invoice is sent directly to the buyer’s system. </li>
                    </ul>
                  </li>
                  <li>
                    <span className={`flex gap-2 font-bold`}>
                      <FaCheck className={`text-primary text-base md:text-lg`} />
                      Receiving Invoices via AgileAP
                    </span>
                    <ul className="list-disc space-y-2 px-8 py-4">
                      <li>AgileAP captures incoming invoices from suppliers via Peppol. </li>
                      <li>The system validates and processes the invoice. </li>
                      <li>The invoice is stored in your system for payment and tax reporting. </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </div>
            <div className={`border-b border-gray-300 py-6 flex flex-col gap-y-8`}>
              <div
                className={`flex justify-between items-center gap-x-8 cursor-pointer`}
                onClick={() => handleChangeQuestion("q-9")}
              >
                <label
                  className={`text-base md:text-lg cursor-pointer ${
                    questionQuery["q-9"] === true ? "text-primary" : "text-secondary"
                  }`}
                >
                  How does AgileAP help businesses comply with the GST InvoiceNow Requirement?{" "}
                </label>
                {questionQuery["q-9"] === true ? (
                  <span className={`text-primary text-2xl md:text-xl cursor-pointer`}>&#8722;</span>
                ) : (
                  <span className={`text-black text-2xl md:text-3xl cursor-pointer`}>&#43;</span>
                )}
              </div>
              <p className={`text-secondary ${questionQuery["q-9"] === true ? "" : "hidden"}`}>
                <p>AgileAP ensures compliance by:</p>
                <ul className="list-item space-y-2 py-4">
                  <li>
                    <span className={`flex gap-2 items-center`}>
                      <FaCheck className={`text-black text-base`} />
                      Transmitting invoice data to IRAS automatically.
                    </span>
                  </li>
                  <li>
                    <span className={`flex gap-2 items-center`}>
                      <FaCheck className={`text-black text-base`} />
                      Ensuring IRAS tax compliance through real-time validation.
                    </span>
                  </li>
                  <li>
                    <span className={`flex gap-2 items-center`}>
                      <FaCheck className={`text-black text-base`} />
                      Providing a secure and seamless e-invoicing solution.
                    </span>
                  </li>
                </ul>
              </p>
            </div>
            <div className={`border-b border-gray-300 py-6 flex flex-col gap-y-8`}>
              <div
                className={`flex justify-between items-center gap-x-8 cursor-pointer`}
                onClick={() => handleChangeQuestion("q-10")}
              >
                <label
                  className={`text-base md:text-lg cursor-pointer ${
                    questionQuery["q-10"] === true ? "text-primary" : "text-secondary"
                  }`}
                >
                  How can businesses sign up for AgileAP?
                </label>
                {questionQuery["q-10"] === true ? (
                  <span className={`text-primary text-2xl md:text-xl cursor-pointer`}>&#8722;</span>
                ) : (
                  <span className={`text-black text-2xl md:text-3xl cursor-pointer`}>&#43;</span>
                )}
              </div>
              <p className={`text-secondary ${questionQuery["q-10"] === true ? "" : "hidden"}`}>
                <ul className="list-disc px-4 space-y-2">
                  <li>
                    Visit{" "}
                    <a href={"https://agileap.com"} className={`text-deepBlue underline`}>
                      www.agileap.com
                    </a>{" "}
                    and register for an InvoiceNow-ready solution.
                  </li>
                  <li>
                    Contact AgileAP for a <b>free demo and consultation</b>.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </section>
        <section className="lg:grid lg:grid-cols-2 text-white bg-darkBlue w-full justify-center pt-12 lg:pl-32 pl-12 md:pl-16 space-y-12 items-center">
          <div className="flex md:w-3/4 gap-y-8 flex-col pb-8 ">
            <label className="font-black text-3xl lg:text-4xl block space-y-2">
              <p>Experience Efficiency,</p>
              <p>Elevate Productivity</p>
            </label>
            <p className={`text-base md:text-lg lg:text-lg`}>
              Transform your accounts payable and accounts receivable processes with AgileAP. Schedule a demo today and
              take the first step towards unlocking your business's full potential.
            </p>
            <div>
              <button
                onClick={() => {
                  Helper.CallAnalyticEvent(window.location.pathname, "Click Call for a free demo Button");
                  handleClick();
                }}
                type="button"
                className="font-medium text-white bg-primary rounded-md hover:ring-0 hover:outline-none hover:bg-opacity-90 active:bg-opacity-70 text-sm px-8 py-3 text-center mr-3 md:mr-0 "
              >
                Call for a Demo
              </button>
            </div>
          </div>
          <div className=" flex mb-0 md:sticky md:right-0 md:min-w-lg ">
            <img src={Content} className=" mb-0" />
          </div>
        </section>
      </div>
    </>
  );

  return <HomeLayout showHeader={true} showFooter={true} mainContent={mainContentDesktop} currentPage="InvoiceNow" />;
}
