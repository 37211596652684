import { ReactElement, JSXElementConstructor } from "react";
import LOGO from "../../assets/AgileAp-Logo-Standard.png";

interface ModalProps {
  visibility: boolean;
  closeEvent: () => void;
  message: string;
  body?: ReactElement<any, string | JSXElementConstructor<any>>;
  button?: ReactElement<any, string | JSXElementConstructor<any>>;
  backdropBlurClass?: string;
  showLogo?: boolean;
}

export const ModalCustomConfirm = ({ message, visibility, ...props }: ModalProps) => {
  return visibility ? (
    <div className="">
      <div
        id="popup-modal"
        tabIndex={-1}
        className="flex justify-center backdrop-blur-md items-center fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full drop-shadow-3xl "
      >
        <div className="relative w-full h-full md:max-w-md md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={props.closeEvent}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center max-h-[79vh] overflow-auto">
              {props.showLogo ? (
                <div className="mx-auto w-fit py-3">
                  <img
                    src={LOGO}
                    className="w-40 cursor-pointer"
                    onClick={() => {
                      window.location.href = "/";
                    }}
                  />
                </div>
              ) : (
                <svg
                  aria-hidden="true"
                  className="mx-auto mb-4 text-gray-400 w-14 h-14 "
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
              )}
              {message != "" && <h3 className="mb-5 text-lg font-normal text-gray-500 ">{message}</h3>}
              {props.body && props.body}

              <div className="flex w-fit mx-auto">{props.button && props.button}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
