import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import ReCaptchaFormComponent from "./ReCaptchaFormComponent";

interface ReCaptchaComponentProps {
    onVerify: (token: string | null) => void;
}
const siteKey:string = process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3? process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3: "6LcyDvoqAAAAADXk5xLvddJMYnuGqMXly-iiEEUA";
export const ReCaptchaComponentV3 = ({onVerify}:ReCaptchaComponentProps) => {
    return (
        <>
            <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                <ReCaptchaFormComponent onVerify={onVerify}/>
            </GoogleReCaptchaProvider>
        </>
    )
}