import { HomeLayout } from "../layouts/HomeLayout";
import { useEffect, useState } from "react";
import { apiLogin } from "../services";
import MetatagHelmet from "../components/MetatagHelmet";
import { Input } from "../components/Inputs/Input";
import { TextArea } from "../components/Inputs/TextArea";
import React, { useRef } from "react";
import { Toast } from "../components/Toast/Toast";
import { FaWhatsapp } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {Helper} from "../utils/helpers";
import {ReCaptchaComponentV3} from "../components/Recaptcha/ReCaptchaComponentV3";
import Logo from "../assets/AgileAp-Logo-icon.png";
import {ModalCustomConfirm} from "../components/Modal/ModalCustomConfirm";

const baseName = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : "";
export const ContactUs = () => {
  const { country } = useParams();
  const emailAddress = "sales@agileap.com";
  const subject = "Inquiry About AgileAP";
  const body =
    "Hello,\n\nI am interested in the AgileAP. I would like to request for a demo. \nInfomation: \nTitle: \nFull Name: \nCompany Name: \nEmail: ";

  const handleGoHome = () => {
    window.history.pushState({}, "Home", baseName);
    window.history.go();
  };
  // useEffect(() => {
  //   Helper.AnalyticPageView(window.location.pathname)
  // }, []);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [errorName, setErrorName] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [messages, setMessages] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const recaptchaRef = React.createRef();
  const [showCheckbox, setShowCheckbox] = useState(false);

  const handleLeaveMessag = async () => {
    Helper.CallAnalyticEvent(window.location.pathname, "Click Leave us a Message Button");
    //const token = await recaptchaRef.current.executeAsync();
    if (name == "" || email == "") {
      if (name == "") setErrorName("Please enter your name");
      if (email == "") setErrorEmail("Please enter a valid email");
      return;
    }
    if (!recaptchaToken) {
      setToastMessage("Please complete the reCAPTCHA.");
      setToastType("error");
      setOpenToast(true);
      return;
    }
    setIsLoading(true);
    // Submit the form along with the recaptchaToken
    let inputJson = {
      name: name,
      email: email,
      messages: messages,
      phoneNumber: phoneNumber,
      companyName: companyName,
    };
    console.log("input json : ", inputJson);
    await apiLogin("auth/recaptcha/v3/submit")
      .post({ id: null, authenticated: true, body: { recaptchaToken: recaptchaToken } })
      .then((resp) => {
        console.log("response data for recaptcha : ", resp);
        if (resp && resp.data === true) {
          apiLogin("credential/sent-contact-email")
            .post({ id: null, authenticated: true, body: inputJson })
            .then((resp) => {
              setIsLoading(false);
              setOpenToast(true);
              setToastMessage("Sent successfully.");
              setToastType("success");
              setTimeout(() => {
                window.history.pushState({}, "", "/contact");
                window.history.go();
              }, 3000);
            });
        }else{
            
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleRecaptchaVerify = (token: string | null) => {
    setRecaptchaToken(token);
    // if(token && !showCheckbox){
    //   checkRecaptchaVerify(token);
    // }
  };

  const checkRecaptchaVerify = (recaptchaToken:string) => {
     apiLogin("auth/recaptcha/v3/submit").post({id: null, authenticated: true, body: { recaptchaToken: recaptchaToken } })
    .then((resp) => {
      console.log("response data for recaptcha : ", resp);
      if(resp && resp.data === true) {
        setShowCheckbox(false);
      }else{
        setShowCheckbox(true);
      }
    })
  }

  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [openToast, setOpenToast] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleConfirm=()=>{
     setShowConfirmModal(true);
  }

  const mainContentDesktop = (
    <>
      <MetatagHelmet
        title="AgileAP - Subscription Plan | Singapore InvoiceNow Compliant"
        description="AgileAP offers a comprehensive suite of features to meet all your accounts payable and account receivable needs."
        url={window.location.href}
      />
      <Toast
        id={"snackbar"}
        open={openToast}
        type={toastType}
        message={toastMessage}
        handleClose={() => setOpenToast(false)}
      />
      {
        showConfirmModal &&
          <ModalCustomConfirm
              visibility={showConfirmModal}
              closeEvent={() => setShowConfirmModal(false)}
              message={"Are you sure do you want leave a message?"}
              button={
                <div className="flex w-fit mx-auto gap-x-8 items-center">
                  <button
                      className={`w-fit bg-primary disabled:bg-borderColor text-white px-4 py-2 text-base rounded-lg cursor-pointer hover:opacity-70`}
                      onClick={handleLeaveMessag}>Yes, I'm sure</button>
                  <button
                      className={`w-fit bg-cancel text-white px-4 py-2 text-base rounded-lg cursor-pointer hover:opacity-70`}
                      onClick={() => setShowConfirmModal(false)}>No, cancel</button>
                </div>
              }
          />
      }
      <div className={`w-lgMain py-10`}>
        <div className={`gap-4 font-bold text-4xl md:text-5xl text-left font-poppins`}>
          <div className={`mb-3`}>Get in touch with us.</div>
          <div className={`mb-10`}>We're here to assist you.</div>
          {/* <div className={`text-base font-normal`}>Get in touch with us. We're here to assist you.</div> */}
        </div>
        <div className={`my-10`}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-7 mb-7">
            <div className="">
              <Input
                id={"Name"}
                name="Name"
                label="Name*"
                readonly={false}
                onChange={(e: any) => {
                  setName(e.target.value);
                  setErrorName("");
                }}
                value={name}
                error={errorName != ""}
                helperText={errorName}
                onBlur={() => {}}
                type="text"
                placeholder="Enter your name"
                customLabelClass=""
              />
            </div>
            <div>
              <Input
                id={"Email"}
                name="Email"
                label="Email Address*"
                readonly={false}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                  setErrorEmail("");
                }}
                value={email}
                error={errorEmail != ""}
                helperText={errorEmail}
                onBlur={() => {}}
                type="text"
                placeholder="Enter a valid email"
                customLabelClass=""
              />
            </div>
            <div>
              <Input
                id={"Phone Number"}
                name="Phone Number"
                label="Phone Number (Optional)"
                readonly={false}
                onChange={(e: any) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                error={false}
                helperText=""
                onBlur={() => {}}
                type="text"
                placeholder="Enter a phone number"
                customLabelClass=""
              />
            </div>
            <div>
              <Input
                id={"Company Name"}
                name="Company Name"
                label="Company Name (Optional)"
                readonly={false}
                onChange={(e: any) => setCompanyName(e.target.value)}
                value={companyName}
                error={false}
                helperText=""
                onBlur={() => {}}
                type="text"
                placeholder="Enter your company name"
                customLabelClass=""
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-7 mb-7">
            <div>
              <TextArea
                id={"Message"}
                name={"Message"}
                label={"Message"}
                required={false}
                onChange={(e: any) => setMessages(e.target.value)}
                error={false}
                helperText={""}
                readonly={false}
                value={messages}
                xs={0}
                sm={0}
                numberOfRows={5}
                customLabelClass="text-fifthColor pb-2"
              />
            </div>
            <div className="sm:flex justify-end items-center mb-7 gap-7">
              <div className={`pb-3 sm:pb-0 flex justify-center`}>
                {/*<div className={`${showCheckbox? '': 'hidden'}`}>
                  <ReCaptchaComponent onVerify={handleRecaptchaVerify} />
                </div>*/}
                {/*{showCheckbox && <ReCaptchaComponent onVerify={handleRecaptchaVerify} />}*/}
                {
                  !showCheckbox && <ReCaptchaComponentV3 onVerify={handleRecaptchaVerify} />
                }
              </div>
              <div className="flex gap-x-8 justify-center">
                {
                  isLoading &&
                      <div className={`flex items-center visible p-1 `}>
                        <img src={Logo} alt="Loading" className="loading-logo-v2" />
                        <span className="pl-4">{"Loading..."}</span>
                      </div>
                }
                <button
                    onClick={handleLeaveMessag}
                    disabled={isLoading}
                    className={`w-fit bg-primary disabled:bg-borderColor text-white px-4 py-2 text-base rounded-lg cursor-pointer ${isLoading? '': 'hover:opacity-70'}`}
                >
                  Leave us a Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-cover bg-center bg-[url('./assets/backgroundV1.png')] w-full py-20">
        <div className="md:flex justify-around gap-4 mx-7 md:mx-0">
          <div>
            <div className="font-medium text-xl mb-4">Contact Info</div>
            <div className={`gap-4 font-bold text-4xl md:text-5xl text-left font-poppins`}>
              <div className={`mb-3`}>We are always</div>
              <div className={`mb-10`}>happy to assist you</div>
            </div>
          </div>
          <div className="space-y-2 mb-4">
            <div className="font-semibold text-xl">Email Address</div>
            <svg width="30" height="29" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18 11H7C6.71875 11 6.5 11.25 6.5 11.5V12.5C6.5 12.7812 6.71875 13 7 13H18C18.25 13 18.5 12.7812 18.5 12.5V11.5C18.5 11.25 18.25 11 18 11Z"
                fill="#8B909A"
              />
            </svg>
            <div
              className="font-semibold text-base cursor-pointer"
              onClick={() => {
                window.location.href = "mailto:sales@agileap.com";
              }}
            >
              sales@agileap.com
            </div>
            <div className="text-base">Assistant hours:</div>
            <div className="text-base">Monday - Friday</div>
            <div className="text-base">6am to 8pm SGT</div>
          </div>
          <div className="space-y-2 mb-4">
            <div className="font-semibold text-xl flex items-center gap-1 cursor-pointer" onClick={() => {
                window.open(`https://wa.me/6580880170`, "", "noreferrer");
              }}>
              WhatsApp <FaWhatsapp className="text-2xl text-[#25D366]" />
            </div>
            <svg width="30" height="29" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18 11H7C6.71875 11 6.5 11.25 6.5 11.5V12.5C6.5 12.7812 6.71875 13 7 13H18C18.25 13 18.5 12.7812 18.5 12.5V11.5C18.5 11.25 18.25 11 18 11Z"
                fill="#8B909A"
              />
            </svg>
            <div
              className="font-semibold text-base cursor-pointer"
              onClick={() => {
                window.open(`https://wa.me/6580880170`, "", "noreferrer");
              }}
            >
              (65) 8088 0170
            </div>
            <div className="text-base">Support hours:</div>
            <div className="text-base">Monday - Friday</div>
            <div className="text-base">6am to 8pm SGT</div>
          </div>
        </div>
      </div>
    </>
  );
  return <HomeLayout showHeader={true} showFooter={true} mainContent={mainContentDesktop} currentPage="Contact Us" />;
};
